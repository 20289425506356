import { defineStore } from 'pinia';
import { computed, watch } from 'vue';

export const useAuthStore = defineStore('authStore', () => {
  const { clear: authLogOut, session: authSession, loggedIn } = useUserSession();
  const { $posthog, $sentry, $insightsClient } = useNuxtApp();
  const mixpanel = useMixpanel();

  const redirectUrl = ref('');

  async function signIn() {
    const route = useRoute();
    const requestUrl = useRequestURL();

    // Sanitize and validate the redirect URL
    const currentUrl = new URL(requestUrl.origin + route.fullPath);
    redirectUrl.value = currentUrl.pathname + currentUrl.search;
    const redirectCookie = useCookie('redirectUrl', { secure: true, path: '/auth', maxAge: 60 });
    redirectCookie.value = encodeURIComponent(redirectUrl.value);
    try {
      await navigateTo('/auth/auth0', {
        external: true,
      });
    } catch (error) {
      $sentry?.captureException(error);
    }
  }

  async function signOut() {
    try {
      await authLogOut();
    } catch (error) {
      $sentry?.captureException(error);
    }
  }

  function identifyUser() {
    if (authSession.value.user) {
      // Identify user for PostHog
      const posthog = $posthog();
      try {
        posthog?.identify(authSession.value.user.id, {
          auth0_id: authSession.value.user.id,
          auth0_permissions: authSession.value.user.permissions,
          email: authSession.value.user.email,
        });
      } catch (error) {
        $sentry?.captureException(error);
      }

      // Identify user for Mixpanel
      try {
        mixpanel.identify(authSession.value.user.id);
      } catch (error) {
        $sentry?.captureException(error);
      }

      // Identify user for Algolia Insights
      try {
        $insightsClient?.('setAuthenticatedUserToken', authSession.value.user.id.replaceAll('|', '_'));
      } catch (error) {
        $sentry?.captureException(error);
      }
    }
  }

  function resetUser() {
    // Reset user  for PostHog
    const posthog = $posthog();

    try {
      posthog?.reset();
    } catch (error) {
      $sentry?.captureException(error);
    }

    // Reset user for Mixpanel
    try {
      mixpanel.reset();
    } catch (error) {
      $sentry?.captureException(error);
    }

    // Reset user for Algolia Insights
    try {
      $insightsClient?.('setAuthenticatedUserToken', undefined);
    } catch (error) {
      $sentry?.captureException(error);
    }
  }

  watch(
    loggedIn,
    (isLoggedIn) => {
      // Skip identification check if on server
      if (import.meta.server) return;

      if (isLoggedIn) {
        identifyUser();
      }

      if (!isLoggedIn) {
        resetUser();
      }
    },
    { immediate: true }
  );

  return {
    // getters
    user: computed(() => authSession.value.user),
    session: computed(() => authSession.value.session),
    isAuthenticated: computed(() => !!loggedIn.value),
    permissions: computed(() => authSession.value.user?.permissions || []),

    // actions
    signIn,
    signOut,
  };
});
