import { defineStore } from 'pinia';
import { computed } from 'vue';

export const useUserStore = defineStore('userStore', () => {
  const { session: authSession } = useUserSession();

  return {
    // getters
    id: computed(() => authSession.value.user?.id || null),
    name: computed(() => authSession.value.user?.name || null),
    email: computed(() => authSession.value.user?.email || null),
    avatarUrl: computed(() => authSession.value.user?.picture || null),
  };
});
